.LeftMenuTemplate {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;

  .sidenav---sidenav---_2tBP,
  .sidenav---sidenav-subnav---1EN61 {
    background: #275362;
    color: white;
  }
  .sidenav---sidenav---_2tBP {
    position: relative;
  }

  .sidenav---sidenav-subnav---1EN61 {
    .sidenav---navitem---9uL5T {
      padding: 10px;
      display: flex !important;
      .sidenav---navicon---3gCRo {
        padding-left: 10px;
      }
      .sidenav---navtext---1AE_f {
        padding-left: 14px;
        line-height: 4vh;
      }
    }

    .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
      padding: 5px 14px 5px 25px !important;
    }
  }

  .breadcrumbs{
    .k-chip-icon{
      font-family: 'Font Awesome 5 Pro'!important;
    }
    .disabledBreadcrumb{
      opacity: 1 !important;
    }
  }
}