.k-list-container {
  li.k-item {
    font-size: small;
  }
}

.GridCustomStyle {
  .k-grid {
    height: 100%;
  }

  .k-grid-header-wrap {
    table {
      min-width: 800px;
    }
  }

  .k-grid-content {
    overflow-y: scroll;
    cursor: pointer;
    // min-height: 200px;
    table {
      user-select: text;
      min-width: 800px;
    }
  }

  input {
    border: 2px solid #dadada;
    border-radius: 7px;
    padding: 8px;
  }

  input:focus {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }

  .k-grid-norecords {
    width: inherit !important;
    background-color: #ededed;
  }

  .grid-no-records {
    padding-top: 10%;
    height: 100%;
    width: 33%;
    display: flex;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .k-grid-norecords :hover {
    // background-color: #ededed00!important;
    cursor: default;

    .list-group {
      background-color: revert;
    }
  }

  .noRecords :hover {
    background-color: transparent;
  }

  .k-grid-container {
    tr.k-state-selected > td {
      background-color: #55B089 !important;
    }

    .k-state-selected {
      background-color: #55B089 !important;
    }

    div.jsx-parser {
      display: contents;
      td {
        border-width: 0 0 0 1px;
        border-color: rgba(33, 37, 41, 0.125);
      }
    }
  }

  .k-grid-edit-cell .k-textbox {
    font-size: 14px;
  }

  .k-filtercell > .k-filtercell-wrapper > input::placeholder {
    font-size: small;
  }

  .k-filtercell > .k-filtercell-wrapper > input {
    font-size: small;
    margin-bottom: 0px;
    height: 25px;
  }

  .k-filtercell > .k-filtercell-wrapper > .k-numerictextbox > .k-numeric-wrap > input {
    font-size: small;
    margin-bottom: 0px;
    height: 25px;
    background-color: #ffffff;
  }

  .k-numeric-wrap{
    border-color: #f8f9fa !important;
    align-items: center;
    background-color: #f8f9fa !important;

    .k-select{
      display: none;
    }
  }

  .k-filtercell {
    .k-datepicker {
      height: 35px;
      .k-dateinput-wrap {
        input {
          font-size: small !important;
          height: 34px;
        }
      }
    }
  }

  .k-grid-toolbar {
    .k-dropdown-button {
      float: right;
      margin-right: 5px;
    }
  }
  .k-pager-numbers li a.k-state-selected {
    color: #fff;
    background-color: #55B089;
    border-color: #55B089;
  }
  .k-pager-numbers li a {
    color: #55B089;
  }
  .page-item.active .page-link {
    background-color: #55B089;
    color: #fff;
    border-color: #55B089;
  }
  .k-pager-wrap .k-link,
  .k-pager-wrap .k-pager-nav {
    color: black;
  }

  .k-button {
    font-size: small;
  }

  .k-header {
    font-size: small;
    border-left-width: 1px;
  }

  .k-grid-header-wrap {
    border-left-width: 1px;
  }

  .k-grid-pager {
    border-width: 1px;
  }

  .k-grid-table {
    td {
      font-size: 14px;
    }
  }

  abbr {
    border-bottom: unset;
  }

  .gridActions {
    display: flex;
    justify-content: space-evenly;
    cursor: default;
    flex-wrap: wrap;

    abbr {
      padding: 3px;
      text-decoration: none;
    }

    .manage-btn {
      //manage icons style and style
      border-radius: 10px;
      font-family: Roboto;
      cursor: pointer;
      // width: auto;
      height: 30px;
    }

    .manageIcons {
      font-size: 1.1em;
      color: #ffffff;
      display: block;
    }
  }

  // .gridPdfPage {
  //   display: none;
  // }

  .fa-comments-o.manageIcons {
    font-size: 1.4em;
  }
}

.k-pdf-export {
  p {
    font-size: 14px;
  }

  .k-master-row {
    td {
      font-size: 12px;
    }
  }

  .k-header {
    .k-link {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
