.form-render {
  all: initial;
  @import "~bootstrap/dist/css/bootstrap.min.css";
  @import "~formiojs/dist/formio.full.css";
  .formio-component-well {
    p {
      li {
        padding: 4px;
      }
    }
  }
  .formio-component-filecomponent .fileSelector{
    padding: 15px;
    border: 2px dashed #ddd;
    text-align: center;
  }
  .formio-component-filecomponent .fileSelector .browse{
    cursor: pointer;
  }
  .formio-component-filecomponent .fileSelector .fa {
    font-size: 20px;
    margin-right: 5px;
  }
  label {
    span {
      color: darkslategray;
    }
  }
  .formio-error-wrapper{
  background-color: transparent;
  border-color: transparent;
    padding: 0;
  }

  .formio-component-select {
    .form-control.is-invalid.dropdown {
      background-position: right calc(2.8em + 0.1875rem) center;
    }
  }

  .formio-component-modal-wrapper{
    .formio-dialog.formio-dialog-theme-default{
      padding-bottom: 50px;
      padding-top: 50px;
      .formio-dialog-content{
        min-height: 80%;
      }
    }
  }

  .formio-component.has-feedback .invalid-feedback {
    display: block;
    color: inherit;
    margin-top: 4px;
  }
  .page-link {
    color: #275362;
  }
  .bg-primary {
    background-color: #275262 !important;
    color: white;
  }
  .text-muted {
    color: #ddd;
  }
  .form-control[disabled="disabled"] {
    background-color: #e9ecef;
  }
  .file {
    background: #f8d7da;
    margin: 10px;
    padding: 0px 13px 10px 13px;
    border-radius: 10px;

    .fileName{
      padding-bottom: 5px;
    }
  }

  nav{
    .pagination{
      flex-wrap: wrap;
    }
  }

  .formio-component-file{
    a:not([href]){
      color: #007bff;
      cursor: pointer;
    }
  }
  // * {
  //   box-sizing: border-box;
  // }
  
  // body {
  //   font-family: sans-serif;
  //   font-size: 16px;
  //   line-height: 20px;
  //   color: black;
  //   background: white;
  // }
  
  .inputRange {
    position: relative;
    height: 5px;
    width: 100%;
  }
  .inputRange input[type=range] {
    -webkit-appearance: none;
    display: block;
    margin: 0;
    width: 100%;
    background: transparent;
  }
  
  .inputRange input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2;
  }
  
  .inputRange input[type=range]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2;
  }
  
  .inputRange input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2;
  }
  
  .inputRange input[type=range]::-ms-fill-lower {
    display: none;
  }
  
  .inputRange input[type=range]::-ms-fill-upper {
    display: none;
  }
  
  .inputRange input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
    box-shadow: 100% 0 0 100% #43e5f7;
  
    
  }
  
  .inputRange input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
  }
  
  .inputRange input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
  }
  /* input[type=range]::-moz-range-progress {
    height: 8px;
    background: #37adbf;
    border: 0;
    margin-top: 0;
  } */
  
  .ticks {
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    margin-top: -6px;
  }
  
  .ticks li {
    position: relative;
    display: flex;
    justify-content: center;
    width: 10px;
    background: #37adbf;
    height: 10px;
    line-height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .ticks .active {
    color: #37adbf;
    
  }
  .ticks .selected::before {
    background: #37adbf;
  }
  .ticks .active.selected::before {
    display: none;
  }
  .labels {
    width: 100%;
  }

  .card-input-element {
      display: none;
  }

  .card-input {
      margin: 10px;
      padding: 00px;
      box-shadow: 0 0 1px 1px #37adbf;
      color: #37adbf;
  }

  .card-input:hover {
      cursor: pointer;
  }

  .card-input-element:checked + .card-input {
      box-shadow: 0 0 1px 1px #37adbf;
      color : #fff;
      background-color: #37adbf;
  }

  .padding-0{
    padding-right:0;
    padding-left:0;
  }
}

.form-render.hideBredcrumb{
  .formio-form nav[aria-label=navigation]{
    display:none;
  }
}

.formLoader{
  position: absolute;
  height: 100%;
  width: 100%;
}