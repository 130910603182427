@import url(~bootstrap/dist/css/bootstrap.min.css);
@import url(~formiojs/dist/formio.full.css);
.form-render {
  all: initial;
  /* input[type=range]::-moz-range-progress {
    height: 8px;
    background: #37adbf;
    border: 0;
    margin-top: 0;
  } */ }
  .form-render .formio-component-well p li {
    padding: 4px; }
  .form-render .formio-component-filecomponent .fileSelector {
    padding: 15px;
    border: 2px dashed #ddd;
    text-align: center; }
  .form-render .formio-component-filecomponent .fileSelector .browse {
    cursor: pointer; }
  .form-render .formio-component-filecomponent .fileSelector .fa {
    font-size: 20px;
    margin-right: 5px; }
  .form-render label span {
    color: darkslategray; }
  .form-render .formio-error-wrapper {
    background-color: transparent;
    border-color: transparent;
    padding: 0; }
  .form-render .formio-component-select .form-control.is-invalid.dropdown {
    background-position: right calc(2.8em + 0.1875rem) center; }
  .form-render .formio-component-modal-wrapper .formio-dialog.formio-dialog-theme-default {
    padding-bottom: 50px;
    padding-top: 50px; }
    .form-render .formio-component-modal-wrapper .formio-dialog.formio-dialog-theme-default .formio-dialog-content {
      min-height: 80%; }
  .form-render .formio-component.has-feedback .invalid-feedback {
    display: block;
    color: inherit;
    margin-top: 4px; }
  .form-render .page-link {
    color: #275362; }
  .form-render .bg-primary {
    background-color: #275262 !important;
    color: white; }
  .form-render .text-muted {
    color: #ddd; }
  .form-render .form-control[disabled="disabled"] {
    background-color: #e9ecef; }
  .form-render .file {
    background: #f8d7da;
    margin: 10px;
    padding: 0px 13px 10px 13px;
    border-radius: 10px; }
    .form-render .file .fileName {
      padding-bottom: 5px; }
  .form-render nav .pagination {
    flex-wrap: wrap; }
  .form-render .formio-component-file a:not([href]) {
    color: #007bff;
    cursor: pointer; }
  .form-render .inputRange {
    position: relative;
    height: 5px;
    width: 100%; }
  .form-render .inputRange input[type=range] {
    -webkit-appearance: none;
    display: block;
    margin: 0;
    width: 100%;
    background: transparent; }
  .form-render .inputRange input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2; }
  .form-render .inputRange input[type=range]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2; }
  .form-render .inputRange input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #b2b2b2; }
  .form-render .inputRange input[type=range]::-ms-fill-lower {
    display: none; }
  .form-render .inputRange input[type=range]::-ms-fill-upper {
    display: none; }
  .form-render .inputRange input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
    box-shadow: 100% 0 0 100% #43e5f7; }
  .form-render .inputRange input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important; }
  .form-render .inputRange input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important; }
  .form-render .ticks {
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    margin-top: -6px; }
  .form-render .ticks li {
    position: relative;
    display: flex;
    justify-content: center;
    width: 10px;
    background: #37adbf;
    height: 10px;
    line-height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;
    cursor: pointer; }
  .form-render .ticks .active {
    color: #37adbf; }
  .form-render .ticks .selected::before {
    background: #37adbf; }
  .form-render .ticks .active.selected::before {
    display: none; }
  .form-render .labels {
    width: 100%; }
  .form-render .card-input-element {
    display: none; }
  .form-render .card-input {
    margin: 10px;
    padding: 00px;
    box-shadow: 0 0 1px 1px #37adbf;
    color: #37adbf; }
  .form-render .card-input:hover {
    cursor: pointer; }
  .form-render .card-input-element:checked + .card-input {
    box-shadow: 0 0 1px 1px #37adbf;
    color: #fff;
    background-color: #37adbf; }
  .form-render .padding-0 {
    padding-right: 0;
    padding-left: 0; }

.form-render.hideBredcrumb .formio-form nav[aria-label=navigation] {
  display: none; }

.formLoader {
  position: absolute;
  height: 100%;
  width: 100%; }
