$primary-color: #275362;

.Navigation {
  position: relative;
  margin: 0 0px 0 15px;
  height: 100%;
  width: 100%;
  overflow: scroll;

  .AppBuilderPage {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
  }

  .breadcrumbs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 30px;
    border-radius: 1rem;
  }

  .breadcrumbs-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    color: $primary-color;
    a {
      color: $primary-color;
      padding-left: 5px;
      font-size: 24px;
    }
    a:focus,
    a:hover {
      text-decoration: none;
    }
    .seperator {
      margin-right: 0.5rem;
      color: #bababb;
      font-size: 24px;
    }
  }
  .searchInput {
    border: 0;
    background: transparent;
    letter-spacing: 1px;
    font-weight: 600;
    color: #1a1a1a;
    height: 30px;
    padding: 0px 0px 5px 10px;
    font-size: 1.75rem;
    font-weight: 300;
    width: 74%;
  }

  abbr {
    text-decoration: none;
  }

  .page-active,
  .contentDiv {
    height: 100%;
  }

  .searchResults {
    width: 99%;
    
    .searchBar {
      display: flex;
      padding-top: 10px;
      padding-left: 15px;

      .fa-search {
        font-size: 25px;
      }
      .fa-backspace {
        font-size: 30px;
        cursor: pointer;
      }
    }
  }

  .appButtons {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .moduleBtn {
      align-items: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .block {
        background-color: $primary-color;
        border-radius: 10px;
        color: white;
        font-size: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 125px;
        width: 125px;
      }

      .titles {
        font-size: 26px;
        color: $primary-color;
        padding: 25px;
      }
    }
  }
}

.searchInput {
  border: 0;
  background: transparent;
  letter-spacing: 1px;
  color: #1a1a1a;
  height: 30px;
  // padding: 0px 1rem 0 2.5rem;
  font-size: 1.75rem;
  font-weight: 300;
  display: inline-block;
  width: 100%;
}
.searchInput:focus {
  outline-color: transparent;
}

.k-popup {
  font-size: small;
  margin: unset;
  padding: unset;
}

.k-item:focus,
.k-list .k-item.k-state-focused {
  box-shadow: unset !important;
}

.k-list .k-item {
  background-color: #2a4f5d;
  color: white;
}

.breadcrumbParent {
  border-bottom: 1px solid $primary-color;
  margin-bottom: 0.8rem;
}

.loaderAnimation {
  display: flex;
  height: 100%;
}

.loaderAnimation > div {
  margin: auto;
}
.searchPageDiv {
  width: 100%;
}
.searchPageDiv .seperator {
  border-bottom: 1px solid $primary-color;
  width: 80%;
  margin-top: 10px;
}
.searchText {
  padding: 1.5em;
}
.searchPageDiv .icon {
  box-sizing: border-box;
  color: #a5b4c4;
  display: block;
  fill: #a5b4c4;
  font-family: MaisonNeue, -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Lucida Grande", sans-serif;
  font-size: 24px;
  height: 26px;
  position: relative;
  text-size-adjust: 100%;
}
.page-inactive {
  display: none;
}
.page-active {
  display: block;
}
