.Navigation {
  .commentsPage {
    $gray-light: #6c757d10;
    $gray-hover: #6c757d20;
    $gray: #6c757d;
    $pblue-light: #4c75f210;
    $pblue: #4c75f2;
    $pblue-hover: #4c75f230;

    .commentsSaveButton {
      padding: 14px !important;
      border-radius: 50% !important;
    }

    .updates {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 100%;
      margin-bottom: 1rem;

      .update {
        background: $gray-light;
        margin-bottom: 0.5rem;
        padding: 0.75rem;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        word-break: break-word;

        &:hover {
          background: $gray-hover;
        }

        .k-button k-primary {
          width: 60px;
          height: 40px;
          margin-left: 30px;
        }

        h5,
        h4 {
          margin: 0;
        }

        h5 {
          color: $gray;
          font-weight: 600;
        }

        h4 {
          color: $gray;
          font-weight: 600;
        }
      }

      .button {
        background: $pblue-light;
        height: 3rem;
        display: flex;
        flex-direction: row;
        color: $pblue;
        width: 13rem;
        & > * {
          align-self: center;
        }

        &:hover {
          background: $pblue-hover;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        flex-direction: row;
        display: flex;
        & > * {
          align-self: center;
        }
      }
    }

    .commentBox {
      align-items: flex-end !important;
      background-color: #ededed;
      padding: 10px 10px 0px 10px;
      border-radius: 10px;
      max-height: 125px;
    }

    thead {
      display: none;
    }

    .k-grid-table {
      border-spacing: 1em;

      td:first-child {
        background-color: #ededed;
        border-radius: 15px;
      }

      .k-grid td {
        border-width: 0px;
      }
    }

    .flexCol {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mentions {
      width: 100%;

      textarea,
      .mentions__highlighter {
        letter-spacing: 2px;
      }
    }

    // .mentions--multiLine {
    //   overflow-y: scroll !important;
    // }
    .mentions__suggestions {
      z-index: 1000;
    }
    .mentions__suggestions__list {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 10pt;
    }

    .mentions__suggestions__item {
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-date{
      padding-bottom: 5px;
      font-size: 13px;
    }

    html,
    body {
      height: 100%;
    }

    html {
      font-family: Arial, Helvetica, sans-serif;
      background: linear-gradient(to right, #57c1eb 0%, #246fa8 100%);
      font-size: 10px;
    }

    body {
      display: grid;
      place-items: center;
    }
    #container {
      min-width: 800px;
      max-width: 1000px;
      max-height: 500px;
      height: 95vh;
      background: #fff;
      border-radius: 15px;
    }
    #chat-message-list {
      grid-area: chat-message-list;
      padding: 60px 20px;
      .panel {
        border-color: #ddd;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      }
      .panel.arrow.left:before {
        left: 0px;
        top: 30px;
        border-right-color: inherit;
        border-width: 16px;
      }
      .panel-body {
        padding: 15px;
      }
      .btn-sm {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      }
      figure.thumbnail {
        display: block;
        padding: 4px;
        margin-bottom: 20px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        text-align: center;
        border-radius: 4px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      .thumbnail img {
        max-width: 100%;
        max-height: 60px;
      }
      .panel:before {
        left: 0px;
        top: 30px;
        border-right-color: inherit;
        border-width: 16px;
      }
      .btn-default {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
      }
    }
  }
}
